#burger{
  display: none;
}

#burger:hover{
  cursor: pointer;
}

.burger-closed{
  width: 100%;
  height: 1px;
  background-color: #313131;
  transition: 1s ;
}

.burger-top-open{
  width: 40px;
  height: 1px;
  background-color: #313131;
  transform: rotate(45deg);
  transform-origin: top left;
  transition: 1s ;
  z-index: 1000;
  overflow: visible;
}

.burger-bottom-open{
  width: 40px;
  height: 1px;
  background-color: #313131;
  transform: rotate(-45deg);
  transform-origin: 10px 5px;;
  transition: 1s ;
}

.sidenav-open{
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  transition: 0.5s ease-in-out;
  background-color: #fafafa;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 998;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;

}

.sidenav-closed{
  height: 100vh;
  width: 0;
  overflow: hidden;
  transition: 0.5s ease-in-out;
  background-color: #fafafa;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 998;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;

}

  @media only screen and (max-width: 748px) {
    #burger{
      position: absolute;
      right: 5vw;
      top: 5vw;
      height: 40px;
      width: 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      z-index: 999;
      overflow: visible;
    }
}