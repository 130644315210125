#logged-in-header{
    background-color: #1e1e1e;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    color: white;

}

#nav-logo{
    height: 50px;
}

.nav-link{
    color: #fff;
    text-decoration: none;
    margin: 0 1rem;
    font-weight: 300;
    font-size: 18px;
    font-family: 'Proxima';
}