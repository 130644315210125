.supportchat-container {
    position: relative;
}

.icon-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.icon-btn {
    background-color: #ffffff;
    border: none;
    border-radius: 15px;
    outline: none;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 50px;
    height: 50px;
    padding: 5;
}

.icon-btn img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.dialog-box {
    display: flex;
    position: fixed;
    bottom: calc(20px + 60px);
    right: 30px;
    width: 300px;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1001;
}

.dialog-content {
    position: relative;
}

.dialog-title {
    position: relative;
    top: 0px;
    left: 0px;
    font: Satoshi,sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 15px;

    color: #313131;
}

.close-btn {
    position: relative;
    top: 3px;
    left: 100px;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 15px;

    color: #313131;
}

.close-btn:hover {
    color: #f00;
}

.dialog-text {
  position: relative;
  top: 0px;
  left: 0px;
  font: Satoshi,sans-serif;
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 15px;
  color: #313131;
}

.email-input {
    width: calc(100% - 80px); /* Adjust width to fit within container box */
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.email-input[readonly] {
    background-color: #f4f4f4;
    opacity: 0.6;
    cursor: not-allowed;
}

.name-input {
    width: calc(100% - 80px); /* Adjust width to fit within container box */
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.name-input[readonly] {
    background-color: #f4f4f4;
    opacity: 0.6;
    cursor: not-allowed;
}

.message-input {
    width: calc(100% - 80px); /* Adjust width to fit within container box */
    height: 100px;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    overflow-y: auto; /* Add vertical scrollbar if needed */
    line-height: 1.5;
    display: block;
}


