.landing-nav-item{
    text-decoration: none;
    padding: 0 1rem;
    color: #A28F89;
    font-size: 18px;
    position: relative;
    margin: 0 10px;
}

#explore-btn{
    border-radius: 8px;
    height: 42px;
    width: 136px;
    border: 1px solid #313131;
    color: #313131;
    font-size: 14px;
    background-color: unset;
    margin: 10px;
}



@media only screen and (max-width: 1100px) {
    #studio-desktop-nav{
        display: none;
    }
}

