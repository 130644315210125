#faqs{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background-color: #fff;
}

.faq-container{
    width: 1200px;
    max-width: 90vw;
    height: 100px;
    border-radius: 8px;
    background-color: #faf9f9;
    border: 3px solid #faf9f9;
    margin: 10px 0;
    padding: 35px 2rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: 0.5s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.faq-container-open{
    width: 1200px;
    max-width: 90vw;
    height: 200px;
    border-radius: 8px;
    background-color: #fff;
    border: 3px solid #faf9f9;
    margin: 10px 0;
    padding: 35px 2rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: 0.5s ease-in-out;

}

.faq-container:hover, .faq-container-open:hover{
    cursor: pointer;
}

.faq-container h6, .faq-container-open h6{
    margin: 0;
    font-weight: 100;
    font-size: 18px;
    width: 90%;
    

}

.open-close{
    width: 20px;
    height: 20px;
    transition: 0.5s ease-in-out;
    margin-left: 5px;

}

.close-close{
    width: 20px;
    height: 20px;
    transition: 0.5s ease-in-out;
    transform: rotate(-45deg);
    margin-left: 5px;

}

.open-answer{
    visibility: visible;
    opacity: 1;
    transition: opacity 0.7s ease-in-out;
    color: #A28F89;
    margin-top: 20px;
    padding-right: 10px;
}

.close-answer{
    opacity: 0;
    transition: 0.7s ease-in-out;
    color: #A28F89;
    margin-top: 20px;
    visibility: hidden;
    padding-right: 10px;

}

#balance{
    width: 95vw;
    max-width: 1400px;
    height: 600px;
    border-radius: 15px;
    background-image: url('../../Assets/balance.png');
    background-size: cover;
    background-position: right;
    margin: 4rem 0;
    display: flex;
    align-items: center;
    padding: 3rem;
}

#balance p{
    color: white;
    font-size: 42px;
    width: 500px;
    max-width: 60vw;
}

@media only screen and (max-width: 748px) {
    #balance{
        height: 400px;
    }
    .faq-container{
        height: 150px;

    }
    .faq-container-open{
        height: 360px;

    }
}