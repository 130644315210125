.modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(17,17,17,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;

}

.modal-container{
    position: relative;
    width: 90vw;
    max-width: 550px;
    max-height: 90vh;
    background-color: white;
    border-radius: 12px;
    border: 1px solid #313131;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    overflow: scroll;

}

.share-container{
    width: 50%;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0 10px;
}

.single-input{
    height: 54px;
    border: 1px solid grey;
    padding-left: 10px;
    font-size: 16px;
    border-radius: 8px;
}

.top-input, .bottom-input, .middle-input{
    height: 54px;
    border: 1px solid grey;
    padding-left: 10px;
    font-size: 16px;
}

.top-input{
    border-bottom: 0;
    border-radius: 8px 8px 0 0 ;
}

.bottom-input{
    border-radius: 0 0 8px 8px ;
}

.middle-input{
    border-bottom: 0;
    border-radius: 0 ;
}

.modal-btn{
    background-color: #313131;
    border: unset;
    border-radius: 8px;
    height: 48px;
    color: white;
    font-size: 16px;
    margin: 1rem 0 ;
}

.close-modal-btn{
    height: 20px;
    width: 20px;
    position: absolute;
    top: calc(2rem + 3px);
}

.modal-header{
    text-align: center;
    margin-top: 0;
}

.modal-event-container{
    padding: 10px;
    padding-left: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

#pay-btns{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.modal-description{
    max-width: 100%;
}

@media only screen and (max-width: 748px) {
    .modal-event-container{
        padding: 10px 0;
        justify-content: space-evenly;
    }
}