.card-element-container {
    padding-top: 10px;
    padding-bottom: 20px;
    margin: 0 auto;

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

/* styles.css */
.card-element {
    margin: 1rem 0;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    margin-right: 5px;
    height: 35px;

}

.card-element input {
    font-size: 18px;
}

.stripe-attribution {
    width: 130px;
}

.card-element.full-width {
    width: 100%;
}

.card-element.narrow-width {
    width: 33%;
}

.cancel-btn{
    width: 45%;
    height: 40px;
    border-radius: 8px;
    background-color: unset;
    border: 1px solid crimson;
    color: crimson;
    margin-top: 20px;
}
