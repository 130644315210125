footer{
    width: 100vw;
    min-height: 200px;
    background-color: #313131;
    color: white;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
}

footer a{
    color: white;
    text-decoration: none;
    font-size: 20px;
    margin: 10px 0;
}

#footer-logo{
    height: 80px;
    width: 80px;
    object-fit: contain;
    object-position: center;
    filter: invert(1);
}

.footer-app{
    height: 48px;
    margin: 0 5px;
    object-fit: contain;
}

#footer-app-links{
    max-width: 90vw;
    
}