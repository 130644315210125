#explore-header{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 2rem;
    border-bottom: 1px solid #dfdfdf;
}

#explore-nav{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    height: 48px;
    border-radius: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

#explore-nav-mobile{
    display: none;
}

.explore-nav-item-active{
    background-color: #a28f89;
    padding: 0 30px;
    height: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    color: white;
}
.explore-nav-mobile-item-active{
    background-color: #a28f89;
    padding: 0 30px;
    height: 100%;
    width: 33%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    color: white;
}

.explore-nav-item{
    height: 100%;
    padding: 0 30px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
}

.explore-nav-mobile-item{
    height: 100%;
    width: 33%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
}

.explore-nav-item:hover{
    cursor: pointer;
    background-color: #f1f1f1;
    transition: 0.3s;
}

.teacher-container{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    width: 20%;
    position: relative;
}

.studio-container{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    width: 20%;
}

.teacher-img{
    width: 100%;
    height: calc(18vw - 3rem);
    object-position: center;
    object-fit: cover;
    border-radius: 12px;

}

.studio-logo{
    width: 100%;
    height: calc(18vw - 3rem);
    object-position: center;
    object-fit: contain;
    border-radius: 12px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.teacher-location{
    color: #717171;
}

.teachers-container{
    display: flex;
    flex-wrap: wrap;
    width: calc(100vw - 2rem);
    margin: 2rem auto;
    position: relative;
    min-height: 70vh;
}
.class-date{
    height: 150px;
    width: 150px;
    background-color: white;
    border-radius: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.class-month{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #a28f89;
    border-radius: 8px 8px 0 0;
    padding: 10px;
}

.month-title{
    font-size: 20px;
    color:white;
    margin: 0
}

.month-date{
    font-size: 36px;
    margin: 0;
}

.event-container{
    width: 90vw;
    max-width: 1400px;
    margin: 0 auto;
    padding: 2rem;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.map-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #a28f89;
    background-color: #a28f89;
    border-radius: 5px;
    padding: 10px;
    min-width: 150px;
    height: 40px;
    margin-top: 10px;
}

.follow-icon{
    position: absolute;
    top: 25px;
    right: 25px;
    width: 30px;
    height: 30px;
}

.header-popover{
    background-color: white;
    min-height: 100px;
    width: 200px;
    border-radius: 8px;
    position: absolute;
    top: 50px;
    right: 0;
    z-index: 999;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.popover-item{
    padding: 1rem;
    margin: 0;
    transition: 0.3s
}

.divider{
    width: 100%;
    height: 1px;
    background-color: #dfdfdf;
    margin: 5px 0;
}

.class-list-btns{
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 1400px) {
    .teacher-container, .studio-container{
        width: 25%;
    }
    .teacher-img, .studio-logo{
        height: calc(23vw - 3rem);

    }
}

@media only screen and (max-width: 1100px) {
    .teacher-container, .studio-container{
        width: 33%;
    }
    .teacher-img, .studio-logo{
        height: calc(31vw - 3rem);

    }
}

@media only screen and (max-width: 850px) {
    .teacher-container, .studio-container{
        width: 50%;
    }
    .teacher-img, .studio-logo{
        height: calc(48vw - 3rem);

    }
}

@media only screen and (max-width: 748px) {
    #explore-nav-mobile{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        width: 100%;
        height: 48px;
        border-radius: 8px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        margin-top: 10px;

    }

    .class-list-btns{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;
    }

    .map-btn{
        margin: 0;
    }

    .class-date{
        width: 100px;
        height: 100px;
    }

    .class-month{
        padding: 5px;
    }

    .month-title{
        font-size: 15px;
    }

    .month-date{
        font-size: 22px;
    }

    .event-container{
        padding: 1rem 0;
    }
}