
#new-account-container{
    max-width: 95vw;
    height: 100vh;
    background-color: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Proxima';
    color: white;
    z-index: 999;
}

#new-account-page{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-image: url('../../Assets/classes.jpg');
    background-position: center;
    background-size: cover;
    
}

.account-input{
    height: 50px;
    width: 400px;
    max-width: 90vw;
    border: unset;
    border-radius: 5px;
    padding-left: 10px;
    margin: 0.5rem 0;
    font-size: 16px;
}

#account-select{
    height: 50px;
    width: 60%;
    border: unset;
    border-radius: 5px;
    padding-left: 10px;
}

#select-container{
    width: 100%;
    max-width: 90vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem 0;

}

#new-account-btn{
    background-color: #eeebeb;
    border: unset;
    height: 40px;
    width: 150px;
    margin: 1rem 0;
    border-radius: 5px;
    font-family: 'Proxima';
}

#white-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(30,30,30,0.7);
}
