#contact-modal{
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    overflow: scroll;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(17,17,17,0.7);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
}

#modal-container{
    width: 1200px;
    max-width: 95vw;
    height: 750px;
    max-height: 90vh;
    background-color: white;
    border-radius: 8px;
    padding: 2rem;
    position: relative;
    overflow: scroll;
}

#close-modal{
    background-color: unset;
    border: unset;
    font-size: 24px;
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    padding: 10px;
    z-index: 1000;
}

#modal-img{
    position: absolute;
    left: 0;
    top: 5%;
    height: 90%;
    width: 50%;
    object-fit: cover;
    border-radius: 0 8px 8px 0;
}

#contact-form{
    position: absolute;
    right: 0;
    top: 5%;
    width: 50%;
    height: 90%;
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    overflow: scroll;
}

.contact-input{
    width: 100%;
    height: 48px;
    margin: 10px 0;
    border: 1px solid #313131;
    border-radius: 8px;
    padding-left: 10px;
    flex-shrink: 0;
}

#contact-msg{
    width: 100%;
    height: 148px;
    margin: 10px 0;
    border: 1px solid #313131;
    border-radius: 8px;
    padding: 10px;
    flex-shrink: 0;
    resize: none;
}

#send-btn{
    background-color: unset;
    border: 1px solid #313131;
    border-radius: 8px;
    height: 38px;
    margin: 5px 0;
    width: 150px;
    flex-shrink: 0;
}

@media only screen and (max-width: 748px) {
    #modal-img{
        display: none;

    }

    #contact-form{
        position: relative;
        width: 100%;
        padding: 0;
    }

    #contact-modal{
        align-items: baseline;
        padding: 5vh 5vw;
    }
}