input[type=date]{
    font-family: sans-serif;
}

.event-input{
    width: 90%;
    margin: 1rem auto;
    height: 50px;
    padding-left: 10px;
    border-radius: 5px;
    border: unset;
    background-color: #fafafa;
}

.normal{
    background-color: #1e1e1e;
    border: unset;
    height: 32px;
    display: flex;
    align-items: center;
    padding-left: 5px;
    overflow: scroll;
}

.fc-event-time, .fc-event-title, .fc-icon{
    color: white;
}