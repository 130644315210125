#profile-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#profile-img{
    width: 350px;
    max-width: 90vw;
    height: 250px;
    object-fit: cover;
    object-position: center;
}

#img-input{
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    max-width: 100vw;
    height: 100%;
}

#profile-avatar{
    width: 200px;
    max-width: 95vw;
    height: 200px;
    border-radius: 100px;
    border: 4px solid #fff;
    position: relative;
    background-size: cover;
    background-position: center;
    margin: 1rem auto;
}

#profile-avatar:hover{
    cursor: pointer;
}

.om-btn.spaced-profile-btn {
    margin: 5px;
  }