
#studio-hero{
    width: 90vw;
    height: 60vh;
    min-height: 400px;
    margin: 1rem auto;
    background-image: url('../../Assets/studio-hero.webp');
    background-position: left;
    background-size: cover;
    border-radius: 15px;
}


#studio-hero-info{
    width: 600px;
    max-width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 50% ;
    padding: 10px;
}

#studio-hero-info h2, #studio-hero-info p{
    color: white;

}

.left-brown{
    background-color: #f3f1f1;
    width: 95%;
    height: 100%;
    border-radius: 0 15px 15px 0;
}

.right-brown{
    background-color: #f3f1f1;
    width: 95%;
    height: 100%;
    border-radius: 15px 0 0 15px;
    margin-left: 5%;
}

.background-brown-left{
    background: linear-gradient(to left, #f3f1f1 80%, #fafafa 100% ) ;
    width: 80vw;
    height: 300px;
    border-radius: 15px;

}

.background-brown-right{
    background: linear-gradient(to right, #f3f1f1 80%, #fafafa 100% ) ;
    width: 80vw;
    height: 100%;
    border-radius: 15px;

}

.background-white{
    background-color: #fafafa;
    width: 80vw;
    height: 100%;
    border-radius: 15px;
}

#features{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0 100px 0;
}

.cta-btn{
    height: 48px;
    width: 168px;
    background-color: #313131;
    border: unset;
    border-radius: 8px;
    color: white;
    font-size: 16px;
}

#pricing{
    background-color: #e8e5e3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

#pricing h2{
    margin-top: 50px;
    font-weight: 100;
}

#mobile-plans{
    display: none;
}

.mobile-pricing-container{
    width: 100%;
    height: 650px;
    border-radius: 15px;
    background-color: #f1f1f1;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.pricing-container{
    width: 400px;
    height: 600px;
    max-width: 90vw;
    border-radius: 15px;
    background-color: #f1f1f1;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

#premium-container{
    width: 400px;
    min-height: 700px;
    max-width: 90vw;
    border-radius: 15px;
    background-color: #f1f1f1;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.pricing-header{
    width: 100%;
    background-color: #313131;
    text-align: center;
    border-radius: 15px 15px 0 0;
}

.pricing-header h4{
    color: #f1f1f1;
    font-weight: 100;
    font-size: 18px;

}

#most-popular{
    background-color: #313131 ;
    width: 70%;
    border-radius: 0 0 15px 15px;
    text-align: center;
}

.check-mark{
    height: 25px;
    width: 25px;
    filter: brightness(100%);
    filter: invert(1);
}

.pricing-feature{
    list-style-type: none;
    display: flex;
    align-items: center;

}

.pricing-feature p{
    font-size: 16px;
    margin: 5px 0 5px 10px;
    color: #A28F89;
    width: 90%;

}

.plan-info{
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.plan-price{
    margin: 0;
    color: #A28F89;
    font-size: 40px;
}

.full-plan-price{
    margin: 0;
    font-size: 32px;
    font-weight: 100;
   
}

.strikethrough-price {
    text-decoration-line: line-through;
    text-decoration-color: #A28F89;
  }
#get-in-touch{
    border: 1px solid #313131;
    background-color: unset;
    border-radius: 8px;
    height: 48px;
    width: 164px;
    font-size: 16px;
    margin-bottom: 100px;

}

#studio-mobile-header, #mobile-hero-info, #mobile-studio-phone, #studio-carousel, #studio-mobile-circle{
    display: none;
}

#desktop-plans{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.studio-feature-txt{
    width: 40vw;
}

@media only screen and (max-width: 1100px) {
    .background-brown-left{
        width: 90vw;
        text-align: right;
    
    }
    #studio-phone, #studio-circle{
        display: none;
    }
    #studio-hero-info{
        display: none;
    }

   #studio-mobile-header{
    display: block;
    font-size: 32px;
    font-weight: 200;
    margin: 0 1.5rem 1.5rem 1.5rem;
   }

   #mobile-hero-info{
    display: flex;
    flex-direction: column;
    width: 90vw;
    margin: 0 auto;
    align-items: center;
   }
}

.mobile-feature{
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    background: linear-gradient(to right, #f3f2f2 0%, #fafafa 100% ) ;
    border-radius: 8px;

}

@media only screen and (max-width: 748px) {
    #studio-hero{
        background-position: -70px;
        height: 250px;
    }

    #studio-footer{
        background-position: -500px;
        height: auto;
    }
    #studio-footer-info{
        width: 90vw;
        margin: 0 auto;
        padding: 10px;
        max-width: unset;
    }

    #studio-footer-info h2{
        font-size: 22px !important;
    }

    #mobile-studio-phone{
        display: block;
        width: 90vw;
        max-width: 300px;
        margin: 1rem auto;
    }

    #studio-carousel{
        display: block;
        width: 90vw;
        margin: 0 auto;
    }

    #studio-features, #desktop-plans{
        display: none;
    }
    #studio-mobile-circle{
        display: block;
        width: 80vw;
        height: 80vw;
        max-width: 300px;
        max-height: 300px;
        border-radius: 50%;
        margin: 2rem auto;
        
    }

    #mobile-plans{
        display: block;
        width: 100%;
    }
}