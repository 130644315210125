#login-container{
    background-color: unset;
    width: 500px;
    max-width: 90vw;
    min-height: 600px ;
    max-height: 90vw;
    border-radius:5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Proxima';
    z-index: 999;
}

#login-page{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100vw;
    background-color: #fafafa;
    overflow: scroll;
    position: relative;
    background-image: url('../../Assets/classes.jpg');
    background-position: center;
    background-size: cover;
    color: white;
}

#login-logo{
    width: 150px;
    height: 150px;
    filter: invert(1);
}


.login-input{
    height: 50px;
    width: 80%;
    margin: 1rem 0;
    border: unset;
    padding-left: 10px;
    background-color: #fafafa;
    font-size: 16px;
    border-radius: 5px;
    font-family: 'Proxima';
}

#login-btn{
    background-color: #eeebeb;
    border: unset;
    height: 40px;
    width: 100px;
    margin: 1rem 0;
    border-radius: 5px;
    font-family: 'Proxima';
}

#terms-btn{
    background-color: unset;
    border: unset;
    height: 40px;
    margin: 1rem 0;
    font-family: 'Proxima';
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    font-size: 16px;
}

#login-overlay{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(30, 30, 30, 0.7);
}

@media only screen and (max-width: 600px) {
    #login-logo{
        width: 30vw;
    }
  }