#teacher-hero{
    width: 90vw;
    height: 60vh;
    min-height: 400px;
    margin: 1rem auto;
    background-image: url('../../Assets/teacher-hero.webp');
    background-position: left;
    background-size: cover;
    border-radius: 15px;
}

#teacher-footer{
    width: 100vw;
    height: 60vh;
    min-height: 400px;
    background-image: url('../../Assets/yoga-sky.webp');
    background-position: bottom;
    background-size: cover;
}

#teacher-hero-info{
    width: 600px;
    max-width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 50% ;
    padding: 10px;
}

#teacher-hero-info h2, #teacher-hero-info p{
    color: white;

}

#teacher-footer-info{
    width: 500px;
    max-width: 50%;
    color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20% ;
    padding: 10px;
}

.left-brown{
    background-color: #f3f1f1;
    width: 95%;
    height: 100%;
    border-radius: 0 15px 15px 0;
}

.right-brown{
    background-color: #f3f1f1;
    width: 95%;
    height: 100%;
    border-radius: 15px 0 0 15px;
    margin-left: 5%
}

#circle-img{
    width: 350px;
    height: 350px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    position: absolute;
    right: 10%;
    bottom: 10%;
    z-index: 998;
}

#phone-img{
    width: 296px;
    height: 573px;
    object-fit: contain;
    object-position: center;
    position: absolute;
    right: 22%;
    top: 5%;
    z-index: 999;
}

#teacher-mobile-header, #mobile-hero-info, #teacher-student-phone, #teacher-carousel{
    display: none;
}

.teacher-feature-txt{
    width: 100%;
}

.mobile-feature{
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    background: linear-gradient(to right, #f3f2f2 0%, #fafafa 100% ) ;
    border-radius: 8px;

}

@media only screen and (max-width: 1500px) {
    .teacher-feature-txt{
        width: 80%;
    }
}
@media only screen and (max-width: 1350px) {
    .teacher-feature-txt{
        width: 70%;
    }
}
@media only screen and (max-width: 1200px) {
    .teacher-feature-txt{
        width: 60%;
    }
}
@media only screen and (max-width: 1100px) {
    #phone-img, #circle-img{
        display: none;
    }
    #teacher-hero-info{
        display: none;
    }

   #teacher-mobile-header{
    display: block;
    font-size: 32px;
    font-weight: 200;
    margin: 0 1.5rem 1.5rem 1.5rem;
   }

   #mobile-hero-info{
    display: flex;
    flex-direction: column;
    width: 90vw;
    margin: 0 auto;
    align-items: center;
   }

   .teacher-feature-txt{
    width: 90%;
}
}



@media only screen and (max-width: 748px) {
    #teacher-hero{
        background-position: -70px;
        height: 250px;
    }

    #teacher-footer{
        background-position: -500px;
        height: auto;
    }
    #teacher-footer-info{
        width: 90vw;
        margin: 0 auto;
        padding: 10px;
        max-width: unset;
    }

    #teacher-footer-info h2{
        font-size: 22px !important;
    }

    #mobile-teacher-phone{
        display: block;
        width: 90vw;
        max-width: 300px;
        margin: 1rem auto;
    }

    #teacher-carousel{
        display: block;
        width: 90vw;
        margin: 0 auto;
    }

    #teacher-features{
        display: none;
    }
}