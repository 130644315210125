#student-hero{
    width: 90vw;
    height: 60vh;
    min-height: 400px;
    margin: 1rem auto;
    background-image: url('../../Assets/student-hero.webp');
    background-position: left;
    background-size: cover;
    border-radius: 15px;
}

#student-footer{
    width: 100vw;
    height: 60vh;
    min-height: 400px;
    background-image: url('../../Assets/student-footer.webp');
    background-position: bottom;
    background-size: cover;
}

#student-hero-info{
    width: 600px;
    max-width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 50% ;
    padding: 10px;
}

#student-hero-info h2, #student-hero-info p{
    color: #313131;

}

#student-footer-info{
    width: 500px;
    max-width: 50%;
    color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20% ;
    padding: 10px;
}

.left-brown{
    background-color: #f3f1f1;
    width: 95%;
    height: 100%;
    border-radius: 0 15px 15px 0;
}

.student-brown{
    background-color: #f3f1f1;
    width: 95%;
    height: 100%;
    border-radius: 15px 0 0 15px;
    margin-left: 5%
}

#student-circle{
    width: 350px;
    height: 350px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    position: absolute;
    right: -3%;
    bottom: 17%;
    z-index: 998;
}

#student-phone{
    width: 296px;
    height: 573px;
    object-fit: contain;
    object-position: center;
    position: absolute;
    right: 8%;
    top: 5%;
    z-index: 999;
}

.student-features{
    display: flex;
    align-items: center;
    width: 1400px;
    max-width: 90vw;
    margin: 0 auto;
    padding: 3rem;
    border-radius: 8px;
    position: relative;
}

.student-features:nth-child(odd){
    background: linear-gradient(to right, #f3f2f2 0%, #fafafa 100% ) ;
}

#student-mobile-header, #mobile-hero-info, #mobile-student-phone, #student-carousel{
    display: none;
}

.feature-txt{
    width: 100%;
}

@media only screen and (max-width: 1260px) {
    #student-phone, #student-circle{
        display: none;
    }
}

@media only screen and (max-width: 1100px) {
    
    #student-hero-info{
        display: none;
    }

   #student-mobile-header{
    display: block;
    font-size: 32px;
    font-weight: 200;
    margin: 0 1.5rem 1.5rem 1.5rem;
   }

   #mobile-hero-info{
    display: flex;
    flex-direction: column;
    width: 90vw;
    margin: 0 auto;
    align-items: center;
   }
}

.mobile-feature{
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    background: linear-gradient(to right, #f3f2f2 0%, #fafafa 100% ) ;
    border-radius: 8px;

}

@media only screen and (max-width: 830px) {
    #student-hero{
        background-position: -70px;
        height: 250px;
    }

    #student-footer{
        background-position: -500px;
        height: auto;
    }
    #student-footer-info{
        width: 90vw;
        margin: 0 auto;
        padding: 10px;
        max-width: unset;
    }

    #student-footer-info h2{
        font-size: 22px !important;
    }

    #mobile-student-phone{
        display: block;
        width: 90vw;
        max-width: 300px;
        margin: 1rem auto;
    }

    #student-carousel{
        display: block;
        width: 90vw;
        margin: 0 auto;
    }

    #student-features{
        display: none;
    }
}