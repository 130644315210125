#classes-table{
    max-width: 1300px;
    width: 100%;
    margin: 1rem auto;
}

#classes-table th, #classes-table td {
    padding: 10px;
    text-align: left;
}

#classes-table tr:hover{
    cursor: pointer;
}

.classes-input{
    height: 50px;
    margin: 10px 0;
    border: unset;
    background-color: #fafafa;
    padding-left: 10px;
    font-size: 16px;
    border-radius: 5px;
    position: relative;
    display: flex;
    align-items: center;
}

.classes-input span{
    position: absolute;
    left: 10px;
}

.price-input{
    position: absolute;
    width: calc(100% - 20px);
    height: 100%;
    background-color: unset;
    border: unset;
    left: 20px;
}

.modal-close-btn{
    border: 2px solid #dfdfdf;
    height: 40px;
    width: 100px;
    margin: 10px auto;
    border-radius: 5px;
    background-color: unset;
    color: grey;

}

input[type=time]{
    font-family: sans-serif;
}

@media only screen and (max-width: 650px) {
    #classes-table{
        display: block;
        max-width: 95vw;
    }
  }