#home{
    display:flex;
    flex-direction: row;
    margin-bottom: 100px
}

#home-image-container{
    width: 70vw;
    height: 80vh;
    min-height: 600px;
    display: flex;
    justify-content: end;
    position: relative;
}

#home-left-container{
    width: 30vw;
    padding: 0 1rem;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.home-choice-info{
    max-width: 350px;
    margin: 0 auto;
    opacity: 1;
    display: block;
    transition: opacity 0.5s ease-in-out;
}

.home-choice-info p {
    color: #A28F89;
    font-family: "Satoshi";
    font-size: 32px;
    line-height: 1.5
}

.home-choice-info-hidden{
    max-width: 350px;
    margin: 0 auto;
    opacity: 0;
    display: none;
    transition: opacity 0.5s ease-in-out;
}

.learn-more-btn{
    border-radius: 8px;
    height: 42px;
    width: 136px;
    border: 1px solid #313131;
    color: #313131;
    font-size: 14px;
    background-color: unset;
}


.img-standard {
    height: 100%;
    width: 32%;
    z-index: 997;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 1rem;
    transition: 0.6s ease-in-out;
  }

  .img-closed {
    height: 100%;
    width: 0;
    overflow: hidden;
    z-index: 997;
    display: flex;
    flex-direction: column;
    justify-content: end;
    transition: 0.6s ease-in-out;

  }
  
  .img-open {
    height: 100%;
    width: 100%;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    background-position: 0 !important;
    transition: 0.6s ease-in-out;


  }

#home-teacher:hover, #home-student:hover, #home-studio:hover{
    cursor: pointer;
}

#home-studio{
    margin-left: 10px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-image: url('../../Assets/studios.webp');
    background-position: -300px;
    background-size: cover;
    transition: 0.6s ease-in-out;

}

#home-student{
    border-radius: 15px;
    margin-left: 10px;
    background-image: url('../../Assets/individual.webp');
    background-position: -200px;
    background-size: cover;
    transition: 0.6s ease-in-out;

}

#home-teacher{
    border-radius: 15px;
    background-image: url('../../Assets/teachers.webp');
    background-size: cover;
    background-position: -80px;
    transition: 0.6s ease-in-out;


}

.image-for{
    color: white;
    font-family: "Satoshi", sans-serif;
    font-size: 40px;
    line-height: 1;
}

#store-links{
    display: none;
}

@media only screen and (max-width: 1000px) {
    #home-image-container{
        width: 100vw;
        height: auto;
        min-height: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }

    #home-left-container{
        width: 100vw;
        min-height: auto;

    }

    #home{
        flex-direction: column;
    }

    .img-standard, .img-closed{
        width: 90vw;
        height: 350px;
        margin: 1rem 0;

    }

    #home-teacher, #home-student, #home-studio{
        background-position: 0;
        border-radius: 15px;
    }
    #store-links{
        display: flex;
        flex-wrap: wrap;
        margin-top: 1rem;
        justify-content: center;
    }
  }