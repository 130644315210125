.profile-left{
    width: 50%;
    padding-right: 1rem;
}

.profile-right{
    width: 50%;
    padding-left: 1rem;
    
}

.primary-img{
    width: 100%;
    height: 500px;
    object-fit: cover;
    object-position: center;
    border-radius: 12px;
}

.profile-logo{
    width: 100%;
    height: 500px;
    object-fit: contain;
    object-position: center;
    border-radius: 12px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;}

.profile-container{
    width: 90vw;
    max-width: 1200px;
    margin: 1rem auto;
}

.profile-event{
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
    padding: 1rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.class-date-sml{
    height: 80px;
    width: 80px;
    background-color: white;
    border-radius: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-right: 10px;
    flex-shrink: 0;
}

.class-month-sml{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #a28f89;
    border-radius: 8px 8px 0 0;
    padding: 5px;
}

.profile-links{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}

.profile-bio{
    resize: none;
    width: 100%;
    background-color: unset;
    border: unset;
    font-size: 16px;
    padding: 1rem 0;
}

.teacher-pass{
    background-color: #313131;
    width: 100%;
    height: 300px;
    border-radius: 8px;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    position: relative;
}

.left-hole{
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: #fafafa;
    top: calc(50% - 25px);
    left: -25px;
    border-radius: 25px;
}

.right-hole{
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: #fafafa;
    top: calc(50% - 25px);
    right: -25px;
    border-radius: 25px;
}

.purchase-pass-btn{
    border: 1px solid white;
    background-color: unset;
    color: white;
    padding: 10px;
    font-size: 12px;
}

.pass-title{
    color: white;
    text-align: center;
}

@media only screen and (max-width: 948px) {

    .profile-left{
        width: 100%;
        padding: 0;
    }

    .profile-right{
        width: 100%;
        padding: 1rem 0;
    }
}

@media only screen and (max-width: 550px) {
    .primary-img{
        height: 350px;
       
    }
    .teacher-pass{ 
        height: 200px;
    }
    .pass-title{
        font-size: 14px;
        margin: 10px;
    }
}