*{
  box-sizing: border-box;
  font-family: 'Satoshi', sans-serif;
  color: #313131;
  flex-shrink: 0;
}
html{
  background-color: #fafafa;
}

.outlet{
  padding: 1rem;
  background-color: #fafafa;
  min-height: calc(100vh - 90px);
  text-align: center;
}

button:hover, a:hover{
  cursor: pointer;
}

.om-btn{
  border: 1px solid #1e1e1e;
  background-color: unset;
  border-radius: 5px;
  padding: 10px;
  min-width: 150px;
  height: 40px;
  color: #1e1e1e;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.cancel-btn{
  background-color: crimson;
  border: unset;
  color: white;
  border-radius: 5px;
  padding: 10px;
  min-width: 150px;
  height: 40px;
}


.primary-btn{
  background-color: #e0dad7;
  color: white;
  height: 48px;
  border-radius: 8px;
  border: unset;
  margin: 1rem 0;
  font-family: 'Satoshi', sans-serif;
  font-size: 18px;
  transition: 0.6s;
  padding: 0 15px;
  color: #272838;
  letter-spacing: -0.5px;
  max-width: 90%;
}

.om-row{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.om-input{
  width: 450px;
  max-width: 90vw;
  margin: 0.5rem auto;
  border: unset;
  padding-left: 10px;
  border-radius: 5px;
  background-color: #fff;
  font-size: 16px;
  height: 50px;
}

.om-header{
  font-family: 'Satoshi', sans-serif;
  font-size: 32px;
  letter-spacing: -0.5px;
  color: #272838;
  font-weight: 400;
}

label{
  font-size: 0.8rem;
}

.desktop-remove{
  display: none !important;
}

.hover:hover{
  cursor: pointer;
}

.hover-with-background:hover{
  background-color: #f1f1f1;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.vertical-spacer{
  margin: 10px 0;
}

.tab-container{
  height: 68px;
  border: 0.4px solid #d4d4d4;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  margin: 1rem 0;
}

.tab{
  height: 50px;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}

.tab-active{
  height: 50px;
  padding:   1rem;
  background-color: #e0dad7;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  border-radius: 4px;
}

.tab-active:hover, .tab:hover{
  cursor: pointer;
}

.tab-square{
  height: 30px;
  width: 30px;
  border: 1px solid rgb(190, 190, 190);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

@font-face {
  font-family: 'Arual';
  src: local('Arual'), url(./Assets/fonts/Arual.ttf) format('truetype');
}
@font-face {
  font-family: 'Proxima';
  src: local('Proxima'), url(./Assets/fonts/proxima.otf) format('opentype');
}


@font-face {
  font-family: "Satoshi";
  src: local("Satoshi"),
   url("./Assets/fonts/Satoshi-Medium.otf") format("opentype");
 }

 @media only screen and (max-width: 748px) {
  .mobile-remove{
    display: none !important;
  }
  .desktop-remove{
    display: block !important;
    flex-wrap: wrap;
  }
 }